import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import CookieBot from 'react-cookiebot';

import { Provider } from 'react-redux';
import store from './store';

import './i18n';

import { Loader } from './components/Ui';

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loader initial show={true} />}>
      <App />
      <CookieBot domainGroupId='5b236403-bccd-43ea-bc19-c2c823ab58d8' />
    </Suspense>
  </Provider>,
  document.getElementById('root'),
);
