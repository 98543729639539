import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import useForm from '../../hooks/useForm';
import { firstAccessForm } from '../../formsConfig/formsConfig';
import { Input, Button, Loader, Text, Modal } from '../../components/Ui';
import { respondTo } from '../../theme/mixin';
import { activateUser } from '../../store/actions/auth';
import Footer from '../../components/Footer/Footer';
import InputField from '../../components/Ui/Input/InputField/InputField';

const FirstAccess = props => {
  const dispatch = useDispatch();

  const userInfo = useSelector(state => state.user.user.userinfo);
  const firstAccess = useSelector(state => state.user.user.first_access_done);
  const loading = useSelector(state => state.user.firstAccessLoading);
  const footercontent = useSelector(state => state.cms.footer);

  const [inIframe, setInIframe] = useState(false);
  const [popup, setPopup] = useState(true);

  useEffect(() => {
    if (window.self !== window.top) {
      setInIframe(true);
    }
  }, []);

  const activateUserHanlder = () => {
    dispatch(activateUser());
  };

  useEffect(() => {
    if (firstAccess) {
      props.history.push('/');
    }
  }, [firstAccess, props.history]);

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    datePickerHandler,
    resetFormHandler,
  } = useForm(activateUserHanlder, firstAccessForm);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        changedDatePicker={datePickerHandler}
        resetFormHandler={resetFormHandler}
        {...inp}
      />
    );
  });

  return (
    <>
      <Wrapper>
        <div className='sides-wrapper'>
          <div className='bg-image'>
            <img src={process.env.PUBLIC_URL + '/images/firstaccess-image.png'} alt='' />
          </div>

          <div className='right-side'>
            <Text primary light size={42}>
              Aderisci all'iniziativa
            </Text>
            <Text size={14} className='mb-20' color='#a3aec5'>
              I campi sono precompilati in base ai dati personali registrati in EasyBook. Nel caso
              in cui si rendessero necessarie modifiche vi invitiamo a rivolgervi all’utente master.
            </Text>

            {userInfo && (
              <div className='fields'>
                <div className='field'>
                  <Text size={13}>Nome</Text>
                  <InputField value={userInfo.firstname} disabled />
                </div>
                <div className='field'>
                  <Text size={13}>Cognome</Text>
                  <InputField value={userInfo.lastname} disabled />
                </div>
                <div className='field'>
                  <Text size={13}>Email</Text>
                  <InputField value={userInfo.email} disabled />
                </div>
                <div className='field'>
                  <Text size={13}>Codice agenzia</Text>
                  <InputField value={userInfo.agency_code} disabled />
                </div>
              </div>
            )}

            <div className='single-wrapper'>
              <div className='single-consens'>{inputs[0]}</div>
              <div className='single-consens'>{inputs[1]}</div>
            </div>
            <form onSubmit={formSubmitHandler}>
              <Button
                center
                fullwidth
                gradient={{ left: '#eb5c3f', right: '#FCC546' }}
                upper
                disabled={loading}
              >
                {loading ? <Loader show={loading} color='#fff' inBtn /> : "Finalizza l'iscrizione"}
              </Button>
            </form>
          </div>
        </div>
      </Wrapper>
      {!inIframe ? <Footer footercontent={footercontent} /> : null}
      <Modal show={popup} showClose={false} close={() => setPopup(false)}>
        <ContentPopupAccess>
          <div>
            <strong className='attention'>Attenzione!</strong>
            Non hai ancora finalizzato la tua iscrizione a MelAWiaggio! Per{' '}
            <strong>iniziare ad accumulare punti</strong> per il tuo prossimo viaggio{' '}
            <strong>completa la tua iscrizione</strong> flaggando Regolamento ed Informativa
            Privacy.
          </div>
          <Button onClick={() => setPopup(false)}>Procedi a compilare il form</Button>
        </ContentPopupAccess>
      </Modal>
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  .bg-image {
    position: absolute;
    left: 15px;
    top: 15px;
    height: calc(100% - 30px);
    width: calc(100% - 30px);

    img {
      width: 100%;
      height: 100%;
    }
  }

  .single-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;

    .single-consens {
      width: 100%;
      font-size: 12px;
      text-transform: uppercase;
      color: #a3aec5;
      font-weight: 500;

      a {
        text-decoration: underline;
      }
    }
  }

  .sides-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px;
    background-color: #fff;
    position: relative;
    height: auto;

    ${respondTo.sm`
      flex-wrap: nowrap;
      height: 640px;
    `};

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .right-side {
      width: 470px;
      background-color: #fff;
      padding: 15px;
      margin: 10px;
      z-index: 1;

      ${respondTo.sm`
        margin: 0 15px 0 0;
      `};

      h4 {
        font-size: 18px;
        color: #fff;
        margin-bottom: 15px;
      }

      p {
        font-size: 12px;
        margin-bottom: 25px;
      }

      button {
        justify-content: center;
      }

      small {
        color: #ffcb98;
        font-weight: 700;
        margin-top: 15px;
        font-size: 10px;
        display: block;
        text-align: center;
      }

      .fields {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .field:last-child {
          margin-bottom: 5px;
        }

        .field {
          width: 100%;
          margin-bottom: 12px;

          span {
            display: block;
            margin-bottom: 3px;
          }

          input {
            height: 40px;
          }
        }

        .field:nth-child(1),
        .field:nth-child(2) {
          width: calc(50% - 7px);
        }
      }
    }
  }
`;

const ContentPopupAccess = styled.div`
  padding-bottom: 25px;
  text-align: center;

  .attention {
    color: red;
    font-size: 22px;
    display: block;
    margin-bottom: 10px;
  }

  button {
    margin: 0 auto;
    margin-top: 30px;
  }
`;

export default FirstAccess;
