import React, { Suspense, useState, useEffect } from 'react';
import { Button, Loader, Modal, Text } from '../../components/Ui';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SessionExpired from '../../components/SessionExpired';
import { connect, useSelector, useDispatch } from 'react-redux';
import * as actionCreators from '../../store/actions';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';
import { clearFirstAccessError } from '../../store/actions/auth';
import { Link, useLocation } from 'react-router-dom';

const MainContent = styled.div`
  flex: 1 0 auto;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: ${props => props.theme.header_mobile_height + 30}px 15px 0 15px;
  background-color: #fff;

  ${respondTo.sm`
		padding-top: 0;
	`}
`;

const BaseLayout = props => {
  const dispatch = useDispatch();

  const {
    cmp: C,
    logo,
    toggleSidebar,
    showSidebar,
    isLogged,
    logout,
    bootApp,
    langs,
    oauths,
    ownLogin,
    multipromo,
    oauthInfo,
    isExpired,
    meError,
    userInfo,
    oauth_profile_url,
    footercontent,
    appConfig: { i18l },
    firstAccess,
  } = props;

  const errorFirstAccess = useSelector(state => state.auth.errorFirstAccess);

  const [accessErrorModal, setAccessErrorModal] = useState(false);
  const [inIframe, setInIframe] = useState(false);
  const [isModalPopUp, setIsModalPopUp] = useState(() => {
    return localStorage.getItem('popUpRegulationNew') === null ? true : false;
  });
  useEffect(() => {
    if (window.self !== window.top) {
      setInIframe(true);
    }
  }, []);
  const location = useLocation();

  useEffect(() => {
    if (userInfo && userInfo.expire_date_limit) {
      const today = new Date();

      if (Date.parse(today) > Date.parse(userInfo.expire_date_limit)) {
        props.history.push('/account-deleted');
      }
    }
  }, [props.history, userInfo]);

  /* useEffect(() => {
    if (errorFirstAccess) {
      setAccessErrorModal(errorFirstAccess);
    }
  }, [errorFirstAccess]); */

  useEffect(() => {
    if (firstAccess !== undefined && !firstAccess && location.pathname !== '/cookie-policy') {
      props.history.push('/first-access');
    }
  }, [firstAccess, props.history]);

  let lazy = null;
  const handleRegolamnetoPopUp = () => {
    setIsModalPopUp(false);
    localStorage.setItem('popUpRegulationNew', true);
  };
  const Content = (
    <>
      <MainContent>
        {firstAccess !== undefined && firstAccess ? (
          <Header
            isLogged={isLogged}
            logo={logo}
            toggleSidebar={toggleSidebar}
            showSidebar={showSidebar}
            logout={logout}
            bootApp={bootApp}
            langs={langs}
            oauths={oauths}
            ownLogin={ownLogin}
            multipromo={multipromo}
            oauthInfo={oauthInfo}
            userInfo={userInfo}
            oauth_profile_url={oauth_profile_url}
            i18l={i18l}
          />
        ) : (
          <div style={{ margin: '15px' }}>
            <Link to='/'>
              <Button arrowLeft>Indietro</Button>
            </Link>
          </div>
        )}
        <main>
          <C isLogged={isLogged} />
        </main>
      </MainContent>
      {!inIframe ? <Footer footercontent={footercontent} /> : null}

      <Modal
        show={accessErrorModal}
        close={() => {
          setAccessErrorModal(false);
          dispatch(clearFirstAccessError());
        }}
      >
        {accessErrorModal}
      </Modal>
      {/* <Modal show={isModalPopUp} hideCloseIcon>
        <p>
          Gentile partecipante, il programma MelAWiaggio ha una grossa novità: <br />
          per te 300 punti extra se farai almeno 2 prenotazioni dal 01/08/2023 al 12/09/2023, per
          partenze dal 01/09 al 30/09, che al 13/09/2023 risulteranno confermate.
          <br />
          Troverai on line il{' '}
          <a
            href={process.env.PUBLIC_URL + '/pdf/regolamento.pdf'}
            target='_blank'
            style={{ 'text-decoration': 'underline', color: '#004B8C' }}
            rel='noopener noreferrer'
          >
            Regolamento
          </a>{' '}
          aggiornato.
        </p>

        <Button active hover onClick={handleRegolamnetoPopUp} className='mt-20' bold>
          Ok
        </Button>
      </Modal> */}
    </>
  );

  let Comp = lazy ? (
    <Suspense fallback={<Loader show={true} />}>{Content}</Suspense>
  ) : (
    <React.Fragment>{Content}</React.Fragment>
  );

  return (
    <React.Fragment>
      {Comp}
      <SessionExpired isExpired={isExpired || meError} />
    </React.Fragment>
  );
};

BaseLayout.defaultProps = {
  type: 'base',
};

const mapStateToProps = state => {
  return {
    isLogged: state.auth.token,
    logo: state.app.config.logo,
    logoinverted: state.app.config.logoinverted,
    langs: state.app.config.langs,
    showSidebar: state.ui.showSidebar,
    oauths: state.app.config.oauths,
    ownLogin: state.app.config.ownlogin.active,
    firstAccess: state.user.user.first_access_done,
    multipromo: state.app.config.settings.multipromo,
    oauthInfo: state.app.oauthInfo,
    isExpired: state.error.sessionExpired,
    meError: state.user.error,
    error404: state.promo.error404,
    codes: state.user.user.codes,
    userInfo: state.user.user.userinfo,
    oauth_profile_url: state.user.user.oauth_profile_url,
    footercontent: state.cms.footer,
    appConfig: state.app.config,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    bootApp: () => dispatch(actionCreators.bootApp()),
    toggleSidebar: () => dispatch(actionCreators.toggleSidebar()),
    logout: () => dispatch(actionCreators.logout()),
    getUserInfo: () => dispatch(actionCreators.getUserInfo()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);
