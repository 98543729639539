import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTransition, useChain, animated } from 'react-spring';
import { HighlightOff } from '@styled-icons/material-twotone';

import { Button, Text } from '../';

const Overlay = styled(animated.div)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  z-index: 99;
`;

const Content = styled(animated.div)`
  width: 90%;
  max-width: 570px;
  background-color: ${props => (props.theme.modal ? props.theme.modal : '#fff')};
  border-radius: 10px;
  position: absolute;
  padding: 40px 15px 20px 15px;
  top: 50px;
  z-index: 998;
  border: 2px solid ${props => (props.type === 'error' ? '#E90000' : 'transparent')};
  color: ${props => props.theme.text.primary};
  box-shadow: 0px 5px 15px #00000026;
`;

const ModalContainer = styled.div`
  position: relative;
`;

const CloseModal = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  cursor: pointer;
`;

const Modal = props => {
  const { close, children, show, type, showClose = true } = props;

  const overlayTransitionRef = useRef();
  const overlayTransition = useTransition(show, null, {
    ref: overlayTransitionRef,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const modalTransitionRef = useRef();
  const modalTransition = useTransition(show, null, {
    ref: modalTransitionRef,
    unique: true,
    trail: 100,
    from: { opacity: 0, transform: 'translateY(-40px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(-40px)' },
  });

  useChain(
    show ? [overlayTransitionRef, modalTransitionRef] : [modalTransitionRef, overlayTransitionRef],
    [0, show ? 0.1 : 0.6],
  );

  return overlayTransition.map(({ item, key, props }) =>
    item ? (
      <Overlay key={key} style={props}>
        {modalTransition.map(({ item, key, props }) =>
          item ? (
            <Content key={key} style={props} type={type}>
              {showClose && (
                <CloseModal onClick={close}>
                  <HighlightOff size={30} />
                </CloseModal>
              )}
              <ModalContainer>
                {children}
                {type === 'error' && (
                  <Button secondary onClick={close} style={{ marginTop: 20, width: 200 }}>
                    Ok
                  </Button>
                )}
              </ModalContainer>
            </Content>
          ) : null,
        )}
      </Overlay>
    ) : null,
  );
};

Modal.propTypes = {
  close: PropTypes.func,
  children: PropTypes.any,
  show: PropTypes.bool,
};

export default Modal;
